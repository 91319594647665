import { graphql, useStaticQuery } from 'gatsby';

export const useProductImages = () => {
  /* eslint-disable no-unused-vars */
  const imageProps = graphql`
    fragment imageProps on File {
      childImageSharp {
        fluid(maxHeight: 500, maxWidth: 500) {
          src
          originalName
        }
      }
    }
  `;

  return useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "products" } }) {
          nodes {
            ...imageProps
          }
        }
      }
    `
  );
};
