import React from 'react';
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import ChipSelect from "./ChipSelect"
import { device } from "../../../../resources/devices"
import { MuiCloseIcon } from "../../../icons/Icons"
import IconButton from "@material-ui/core/IconButton"
import withStyles from "@material-ui/core/styles/withStyles"

const MainContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 550;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    background-color: white;
    @media screen and ${device.mobileS} {
      height: 30vh;
    }
    @media screen and ${device.mobileM} {
      height: 20vh;
    }
    @media screen and ${device.tablet} {
      height: 10vh;
    }
    transform: translateY(-100%);
`


const CloseButton = withStyles({
  root: {
    "&:focus": {
      outline: 'none'
    }
  }
})(IconButton)

const Menu = ({ open, closeMenu }) => {
  return <CSSTransition
    in={open}
    timeout={1000}
    classNames="menu"
  >
    <MainContainer>
      <ChipSelect />
      <CloseButton color={'primary'} onClick={closeMenu}>
        <MuiCloseIcon />
      </CloseButton>
    </MainContainer>
  </CSSTransition>
}

export default Menu;
