import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"
import { useTranslation } from "react-i18next"
import products from "../../../../resources/products.json"
import { useDispatch, useSelector } from "react-redux"
import { setCategories } from "../../../../redux/actions/actions"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '40vw',
    maxWidth:'70vw'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor:'#3f51b5'
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const ChipSelect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = products.categories;
  const selected = useSelector(state => state.categories)
  const { t } = useTranslation(['product-menu', 'product_categories'])

  const handleChange = (event) => {
    dispatch(setCategories(event.target.value))
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{t('select-label')}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value.code} label={t(`product_categories:${value.name}`)} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {categories.map((val) => (
          <MenuItem key={val.code} value={val}>
            {t(`product_categories:${val.name}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ChipSelect;
