import React from 'react';
import '../resources/css/style.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductPageBody from '../components/page/products/ProductPageBody';
import pages from '../resources/pages.json';

const ContactPage = () => (
  <Layout>
    <SEO title={pages.products.title} description={pages.products.description} />
    <ProductPageBody />
  </Layout>
);

export default ContactPage;
