import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { CartIcon } from '../../icons/Icons';
import pages from '../../../resources/pages.json';
import { Link } from 'gatsby';

const useStyles = makeStyles({
  root: {
    width: '300px',
    height: '18rem',
  },
  media: {
    width: '300px',
    height: '12rem',
  },
  content: {
    padding: '2px 8px',
  },
  actions: {
    justifyContent: 'space-between',
    padding: '0 8px 2px 8px',
  },
});

const ProductCard = ({ title, img }) => {
  const classes = useStyles();
  const { t } = useTranslation('product-card');

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={img} />
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" component="h2">
          {t(title)}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        {
          //todo: add details view
          /*<Button size="small" color="primary" variant={'contained'}>
          <MuiDetailsIcon />
          {t('more')}
        </Button>*/
        }
        <Link to={pages.contact.uri} style={{ textDecoration: 'none' }}>
          <Button size="small" color="primary" variant={'contained'}>
            <CartIcon />
            {t('buy')}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
