import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../../resources/devices';
import ProductCard from './ProductCard';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';
import Menu from './menu/Menu';
import { useProductImages } from './ProductImage';
import { useSelector } from 'react-redux';

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 1fr;
  grid-gap: 5vw;
  padding: 0 1vw;
  width: 100%;
  justify-content: center;
  overflow-y: scroll;
  scroll-behavior: smooth;
  @media screen and ${device.desktop} {
    height: 78vh;
  }
  height: 85vh;
  margin-top: 5vh;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }
`;

const FilterButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #f85047 30%, #FF8E53 90%)',
    '&:hover': {
      filter: 'brightness(85%)',
    },
    '&:focus': {
      filter: 'brightness(85%)',
      outline: 'none',
    },
    borderRadius: 6,
    border: 0,
    color: 'white',
    height: '3rem',
    width: '7rem',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
})(Button);

const BackButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 3%;
  left: 3%;
`;

const ProductPageBody = () => {
  const { t } = useTranslation(['products', 'common']);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const data = useProductImages();

  const displayedProducts = useSelector((state) => state.products);
  const handleClick = () => {
    if (isMenuOpen) {
      setMenuOpen(false);
    }
  };

  const findImg = (product) => {
    const nodes = data.allFile.nodes;
    for (let i = 0; i < nodes.length; i++) {
      let n = nodes[i].childImageSharp.fluid;
      if (product.images[0] === n.originalName) return n.src;
    }
  };

  const closeMenu = () => setMenuOpen(!isMenuOpen);

  return (
    <>
      <Menu open={isMenuOpen} closeMenu={closeMenu} />
      <MainContainer onClick={handleClick}>
        {displayedProducts.map((p) => (
          <ProductCard title={t(p.name)} img={findImg(p)} key={p.images[0]} />
        ))}
        <br />
      </MainContainer>
      <BackButtonWrapper>
        <FilterButton onClick={closeMenu}>{t('common:filter')}</FilterButton>
      </BackButtonWrapper>
    </>
  );
};

export default ProductPageBody;
